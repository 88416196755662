import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { DialogActions, DialogContent, Link, Typography, Switch, FormControlLabel } from "@mui/material";
import { Link as RouteLink, useNavigate } from "react-router-dom";
import moment from 'moment';

//style
import { Title, Container } from "../../../styles/globalComponents";
import {
    Wrapper,
    DoubleContainer,
    ButtonComponent,
    WrapperButton,
} from "../../../styles/globalComponents";
import { SubLabelInput, WrapperInput } from "../../styles/Input";
//translate
import { useTranslation } from "react-i18next";

//components
import { InputComponent } from "../Input";
import { CalendarComponent } from "../../../components/calendar";
import { SelectComponent } from "../../../components/select";
import { RadioComponent } from "../radio";
import { CheckComponent } from "../check";
import { ModalGeneric } from "../../../components/modalGeneric";
import { Loading } from "../../../components/loading";
import { AlertComponent } from "../../../components/alert";

//interace
import {
    InterfaceRegisterProps,
    InterfaceInputRegister,
} from "../../interfaces/register";
import { InterfaceStyle } from "../../../interfaces/interface";

//validators
import { schemaRegister } from "../../validators/schemaRegister";

//data
import { dataRegister, dataCountryArray, INIT_DATA_USER_FORM } from "../../data/register";

//api
import { useGetRolesQuery, usePostSignupMutation } from "../../api/auth";
import { selectRole } from '../../slices/role';

//hoks
import { useAppSelector } from "../../../hooks/redux";
import { getRadioRoles } from "../../utils/user";

export const FormRegister = (props: InterfaceStyle) => {
    const { isLoading: role } = useGetRolesQuery();
    const [t, i18n] = useTranslation("global");

    const [confirmUser, setConfirmUser] = useState(false);
    const [isErrorModal, setIsErrorModal] = useState(false);
    const [textModal, setTextModal] = useState('');
    const [showRoleType, setShowRoleType] = useState(false);
    //const [showSlider, setShowSlider] = useState(false);
    //const [isIndividualWork, setIsIndividualWork] = useState(false);
    //const [hasTutor, setHasTutor] = useState(false);


    const [
        postSignup,
        { isLoading, isSuccess, isError, data: dataRecived, error: errorRecived },
    ] = usePostSignupMutation();

   /* const handleIndividualWorkChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
        setIsIndividualWork(event.target.checked);
    };*/

    /*const handleHasTutorChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
        setHasTutor(event.target.checked);
    };*/

    const navegate = useNavigate();
    const roles = useAppSelector(selectRole);

    const { control, register, handleSubmit, formState: { errors }, setError } = useForm<InterfaceRegisterProps>({
        defaultValues: INIT_DATA_USER_FORM,
        resolver: yupResolver(schemaRegister(t)),
    });

    const selectedRole = useWatch({
        control,
        name: 'role',
    });

    useEffect(() => {
        const selectedRoleData = roles.find(role => role._id === selectedRole);
        if (selectedRoleData && selectedRoleData.name.toLowerCase() === 'tutor') {
            setShowRoleType(true);
            //setShowSlider(false);
            //setHasTutor(false);
            //setIsIndividualWork(false);
        } else if (selectedRoleData && selectedRoleData.name.toLowerCase() === 'representative') {
            setShowRoleType(false);
            //setShowSlider(true);
        } else {
            setShowRoleType(false);
            //setShowSlider(false);
            //setHasTutor(false);
            //setIsIndividualWork(false);
        }

    }, [selectedRole, roles]);

    const handleSumbitRegister = async (data: InterfaceRegisterProps) => {
        try {

            for (let prop in data) {
                //console.log(prop);
                if (typeof (prop) === 'string') {
                    prop = prop.trim();
                }
                //console.log(prop);
            };

            for (let i = 0; roles.length > i; i++) {
                if (roles[i]._id === data.role) {
                    if (roles[i].name && roles[i].name.toLocaleLowerCase() === 'representative') {
                        if (!data.schoolCenter) {
                            return setError('schoolCenter', { type: 'custom', message: t("zUsers.validators.schemaRegister.schoolCenter-req") }, { shouldFocus: true });
                        }
                    } else if (roles[i].name && roles[i].name.toLocaleLowerCase() === 'tutor') {
                        if (!data.roleType) {
                            return setError('roleType', { type: 'custom', message: t("zUsers.validators.schemaRegister.roleType-req") }, { shouldFocus: true });
                        }
                    }
                    break;
                }
            }
            delete data.polity;
            if (data.birthday) {
                data['birthday'] = moment(data.birthday).format('YYYY-MM-DD');
            }
            const userReturn = await postSignup(data).unwrap();
            if ("status" in userReturn && userReturn.status === 400) {
                if (userReturn.message === 'email in use') {
                    setTextModal(t("zUsers.components.form.register.exist-account"));
                } else if (userReturn.message === 'email cannot be sended') {
                    setTextModal(t("zUsers.components.form.register.problem-email"));
                }
                setConfirmUser(true);
            } else if ("user" in userReturn) {
                setTextModal(t("zUsers.components.form.register.validate-account"));
                setConfirmUser(true);
            }
        } catch (error) {
            setIsErrorModal(true);
            console.log(error);
        }
    };

    return (
        <Container width={props.width} heigth={props.heigth}>
            <Title>{t("zUsers.components.form.register.validate-account")}</Title>
            <Wrapper>
                <form noValidate onSubmit={handleSubmit(handleSumbitRegister)} autoComplete="off">
                    {dataRegister(t, errors).map((items) => {
                        if (Array.isArray(items)) {
                            return (
                                <DoubleContainer key={items[0].registerLabel}>
                                    {items.map(
                                        ({
                                            registerLabel,
                                            errors,
                                            subLabel,
                                            label,
                                            type,
                                            mask,
                                            placeholder,
                                        }) => {
                                            if (registerLabel === "roleType") {
                                                return null; // Exclude roleType here
                                            } else if (registerLabel === "country") {
                                                return (
                                                    <SelectComponent<InterfaceRegisterProps>
                                                        key={registerLabel}
                                                        label={label}
                                                        data={dataCountryArray}
                                                        formControl={{
                                                            control,
                                                            name: "country",
                                                        }}
                                                        placeholder={""}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <InputComponent
                                                        key={registerLabel}
                                                        {...register(registerLabel)}
                                                        errors={errors}
                                                        label={label}
                                                        subLabel={subLabel}
                                                        type={type}
                                                        mask={mask}
                                                        placeholder={placeholder}
                                                    />
                                                );
                                            }
                                        }
                                    )}
                                </DoubleContainer>
                            );
                        } else {
                            if (items.registerLabel === "birthday") {
                                return (
                                    <CalendarComponent<InterfaceRegisterProps>
                                        key={items.registerLabel}
                                        label={items.label}
                                        subLabel={items.subLabel}
                                        plataform={props.responsive || "desktop"}
                                        formControl={{
                                            control,
                                            name: "birthday",
                                        }}
                                    />
                                );
                            } else if (items.registerLabel === "roleType") {
                                return null; // Exclude roleType here
                            } else {
                                return (
                                    <InputComponent
                                        key={items.registerLabel}
                                        {...register(items.registerLabel)}
                                        errors={items.errors}
                                        label={items.label}
                                        subLabel={items.subLabel}
                                        placeholder={items.placeholder}
                                        type={items.type}
                                        mask={items.mask}
                                    />
                                );
                            }
                        }
                    })}



                    <WrapperInput>
                        <RadioComponent<InterfaceRegisterProps>
                            formControl={{ control, name: "role" }}
                            errors={errors.role}
                            radio={getRadioRoles(roles, t)}
                        />
                    </WrapperInput>

                    {showRoleType && (
                        <WrapperInput>
                            <InputComponent
                                key={"roleType"}
                                {...register("roleType")}
                                errors={errors.roleType}
                                label={t("zUsers.data.register.roleType")}
                                placeholder={t("zUsers.data.register.roleType-holder")}
                                type="text"
                            />
                        </WrapperInput>
                    )}

                    <WrapperInput paddingBottom="0" paddingTop="0">
                        <CheckComponent<InterfaceRegisterProps>
                            formControl={{ control, name: "polity" }}
                            errors={errors.polity}
                        />
                    </WrapperInput>
                    <WrapperInput>
                        <SubLabelInput>
                            {t("zUsers.components.form.register.already-account")}{" "}
                            <RouteLink to="/profile">
                                <Link color="black" underline="hover">
                                    {t("zUsers.components.form.register.login")}
                                </Link>
                            </RouteLink>
                        </SubLabelInput>
                    </WrapperInput>
                    <WrapperButton>
                        <ButtonComponent className="btn-hover">
                            {t("zUsers.components.form.register.sign-up")}
                        </ButtonComponent>
                    </WrapperButton>
                </form>
            </Wrapper>
            <Loading open={isLoading} />
            <AlertComponent
                verify={"error"}
                open={isErrorModal}
                onClose={() => setIsErrorModal(false)}
                text={`${t("zUsers.components.form.register.register-error")}`}
            />
            <ModalGeneric isOpen={confirmUser} handleClose={() => setConfirmUser(false)}>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {textModal}
                    </Typography>
                </DialogContent>
                <DialogActions style={{ padding: "2%" }}>
                    <ButtonComponent
                        className="btn-hover"
                        type="button"
                        onClick={() => navegate('/auth/signin')}
                    >
                        {t("zUsers.components.form.register.accept")}
                    </ButtonComponent>
                </DialogActions>
            </ModalGeneric>
        </Container>
    );
};