import styled  from 'styled-components'

//stylesInfo
import { colors, fonts } from './Info'

//icons
import { GrReturn } from "react-icons/gr";

//interface
import { InterfaceStyle } from '../interfaces/interface'
import { Autocomplete } from '@mui/material';
import { styled as styledMui } from "@mui/material/styles";


export const Container = styled.div<InterfaceStyle>`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.heigth || 'auto'};
    padding: ${(props) => props.padding || '0'};
    padding-left: ${(props) => props.paddingLeft || '0'};
    margin: 0 auto;
    margin-top: 1.0rem;
    position:relative;
    padding-bottom: ${(props) => props.paddingBottom || '0'};
    padding-right: ${(props) => props.paddingRight || '0'};
    word-break: keep-all;
`;

export const ContainerBuild = styled.div<InterfaceStyle>`
    width: ${(props) => props.width || '100%'};
    height: 85vh;
    radius: 15%;
    margin-bottom: 1.0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const Title = styled.h1`
    
    font-size: 1.5em;
    color: ${colors.textIcons.dark}
    display: inline-block;
`

export const TitleWrapper = styled.h1`
    padding: ${(props) => props.padding || '5% 0 0 5%'};
    margin: 0;
    font-size: ${(props:InterfaceStyle) => props.fontSize || '1.5em'};
    color: ${colors.textIcons.dark};
    display: inline-block;
`

//icons

export const ReturnIco = styled(GrReturn)`
    align-items: center;
    height: auto;
    font-size: x-large;
    font-family: ${fonts.body};
    text-decoration: none;
    &:hover {
        color: ${colors.background.darkGrey};
      }
`
export const Wrapper = styled.div<InterfaceStyle>`
    
    background: ${colors.background.light};
    display: flex;
    border-radius: 0.5rem;
    box-shadow: 1px 1px 5px black;
    flex-wrap: wrap;
    height: ${(props) => props.heigth || 'auto'};
    padding: ${(props) => props.padding || 'auto'};
    margin:${(props) => props.margin || '0'};
    align-items:${(props) => props.alingItems || 'auto'};

    form {
        padding: ${(props) => props.padding || '2rem'};
        gap: 0.3rem;
        width:100%;
        height:100%;
    }
   
`;

export const DoubleContainer = styled.div<InterfaceStyle>`
    display: ${(props) => props.display || 'grid'} ;
    grid-template-columns: ${(props) => props.gridTemplateColumns || '1fr 1fr'};
    gap: 1.2rem;
    width: ${(props) => props.width || 'auto'};
    padding: ${(props) => props.padding || '0'};
    word-break: break-all;
    align-items: ${(props) => props.alingItems || 'auto'};
    font-family: ${fonts.titleArchive.fontFamily};


`;

export const TripleContainer = styled.div<InterfaceStyle>`
    display: ${(props) => props.display || 'grid'} ;
    grid-template-columns: ${(props) => props.gridTemplateColumns || '1fr 5fr 1fr'};
    gap: 1.2rem;
    width: ${(props) => props.width || 'auto'};
    padding: ${(props) => props.padding || 'auto'};
    justify-items: ${(props) => props.justifyItems || 'start'};;
    align-items: center;

`;
export const ButtonComponent = styled.button<InterfaceStyle>`
    width: ${(props) => props.width || '100%'};
    font-weight: 600;
    background-color: ${(props) => props.background || colors.background.button};
    color: ${(props) => props.color || colors.background.dark};
    cursor: pointer;
    height: auto; /* Ajustar automáticamente según el contenido */
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 10px;
    transition: all .4s ease-in-out;
    outline: none;
    padding: 10px; /* Espacio interno más uniforme */
    text-transform: uppercase;

    /* Ajustar texto para dividir correctamente */
    white-space: normal;        /* Permitir texto en múltiples líneas */
    word-break: break-word;     /* Dividir palabras largas */
    overflow-wrap: anywhere;    /* Divide texto en cualquier lugar razonable */

    &:hover {
        box-shadow: 0 4px 15px 0 rgba(109, 109, 110, 0.75);
    }
`;

export const ButtonComponentDisabled = styled.button<InterfaceStyle>`
    width: 100%;
    font-weight: 600;
    background-color : ${colors.background.lightGrey};
    color: ${colors.textIcons.placeholderDisabled};
    cursor: pointer;
    height: 55px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
    border-radius: 10px;

`

export const WrapperButton = styled.div<InterfaceStyle>`
    padding-top:  ${(props) => props.paddingTop || '5%'};;
    display: flex;
    justify-content: center;
`;


export const ContainerGeneral = styled.div<InterfaceStyle>`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.heigth || 'auto'};
    padding-bottom: 5%;
    margin: 0 auto;
    position:relative;
    word-break: keep-all;
`;


export const TitleWrapperGeneral = styled.p<InterfaceStyle>`
    font-size: ${(props) => props.fontSize || `calc(100% + ${fonts.titleArchive.fontSize}`});
    font-family: ${fonts.titleArchive.fontFamily};
    color: ${colors.textIcons.dark};
    line-height: ${(props) => props.lineHeight || fonts.titleArchive.lineHeight};
    margin: 5% 0 0 0
`

export const SubTitleWrapperGeneral = styled.p<InterfaceStyle>`
    font-family: ${fonts.subtitleArchive.fontFamily};
    font-size: ${(props) => props.fontSize || `calc(100% + ${fonts.subtitleArchive.fontSize}`});
    line-height: ${(props) => props.lineHeight || fonts.subtitleArchive.lineHeight};
    text-decoration: ${(props) => props.textDecoration || 'auto'};
    color: ${colors.textIcons.dark};
    margin-bottom: 5px;
    padding: ${(props) => props.padding || 'auto'};
    padding-botton:4%;
    word-break: keep-all;
`

export const WrapperGeneral= styled.div<InterfaceStyle>`
    
    background: ${colors.background.light};
    display: flex;
    border-radius: 0.5rem;
    box-shadow: 1px 1px 5px black;
    flex-wrap: wrap;
    transition: all .4s ease-in-out;
    padding: ${(props) => props.padding || 'auto'};
    outline: none;
   
    &:hover { 
        box-shadow: 0 4px 15px 0 rgba(109, 109, 110, 0.75);
    }
   
`;

export const StyledAutocomplete = styledMui(Autocomplete)`
    & .MuiIconButton-root {
        padding: 1px;
    }
`;

export const CursorPointer =`
    &:hover { 
        cursor: pointer
    }
`;