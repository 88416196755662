//React Modules
import { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputAdornment, IconButton } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { Collapse, Box } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead,
    TextField,
    DialogContent,
    DialogActions,
} from "@mui/material";

// Interfaces
import {
    InterfaceTableBase,
    InterfaceTableForm,
    InterfaceTableContent,
    InterfaceTableContentFilter,
} from "../../interfaces/table";
import { InterfaceProjectLarge } from "../../../interfaces/project";
import { InterfaceDataUser, InterfaceRoleUser } from "../../../interfaces/user";
import { InterfacePropsDataMenuUser } from "../../interfaces/users";

//styles
import { NavbarLink } from "../../../styles/nav";

import {
    ButtonComponent,
    DoubleContainer,
    TitleWrapper,
    ReturnIco
} from "../../../styles/globalComponents";
import {
    LabelInput,
    SubLabelInput,
    WrapperInput,
} from "../../../zUsers/styles/Input";

//Components
import { AutocompleteComponent } from "../../../components/autocomplete";
import { TablePaginationGeneral } from "../paginate";
import { MoreComponent } from "../more";
import { Loading } from "../../../components/loading";

//hooks
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useForm } from "../../../hooks/form";

//data
import { dataMenuUser } from "../../data/users";

//api
import {
    useGetListUserQuery,
    usePostUserEmailMutation,
    usePutDeactivateAdminMutation,
    useDeleteProfileMutation
} from "../../api/users";
import { usePutDeactivateMutation } from "../../../zUsers/api/auth";

//utils
import { dateComplet, rolesViews } from "../../../utils/utils";

//slices
import { activeAlertAction } from "../../../slices/alerts";
import moment from "moment";
import { ModalGeneric } from "../../../components/modalGeneric";
import { StyledMultiline } from "../../styles/materialUi";
import { selectRole } from "../../../zUsers/slices/role";
import { filterDataUsers, rolesChangues } from "../../utils/users";

//translate
import { useTranslation } from "react-i18next";
import { South } from "@mui/icons-material";

export const TableUserForm = memo(
    ({ title, colum, select, button, props }: InterfaceTableBase): JSX.Element => {
        //form hooks
        const {
            handleChange,
            page,
            rowsPerPage,
            search,
            count,
            data,
        } = useForm<InterfaceTableForm<InterfaceDataUser>>({
            count: 0,
            page: 0,
            rowsPerPage: 9999,
            search: "",
            filter1: "",
            filter2: "",
            data: [],
        });

        const [filter1, setFilter1] = useState('')
        const [filter2, setFilter2] = useState('')

        const [isDataReady, setIsDataReady] = useState(false);  // Nuevo estado

        //roles
        const roles = useAppSelector(selectRole);

        const [t, i18n] = useTranslation("global");

        const queryParams = {
            skip: rowsPerPage * page,
            limit: rowsPerPage,
            search
        };

        //console.log("Query Params: ", queryParams);

        //apis
        const {
            isLoading,
            isFetching,
            currentData: rows,
        } = useGetListUserQuery(queryParams);


        //console.log("isLoading:", isLoading);
        //console.log("isFetching:", isFetching);
        //console.log("rows:", rows);

        //filter data of select
        const filterDataSelect = (
            data: InterfaceDataUser[],
            key: keyof InterfaceDataUser
        ) => {
            const dataReturn: Array<string> = [];
            if (key === "role") {
                const rolesSpanish = roles ? rolesChangues(roles, t) : [];
                for (const itemRole of rolesSpanish) {
                    dataReturn.push(itemRole.name);
                }
            }
            for (const items of data) {
                if (key === "records") {
                    const arrayRecords = items[key] as InterfaceProjectLarge[];
                    for (const project of arrayRecords) {
                        let nameFinishConvocations = "Sin convocatoria";
                        if (project.project) {
                            if (project.project.announcement && project.project.announcement.initialDate) {
                                const dateAnnonun = moment(project.project.announcement.initialDate).format("YYYY");
                                const nameConvocations = project.project.announcement.name;
                                nameFinishConvocations = `${dateAnnonun} - ${nameConvocations}`;
                            } else {
                                nameFinishConvocations = "Sin convocatoria";
                            }
                        } else {
                            nameFinishConvocations = "Sin proyecto";
                        }

                        if (!dataReturn.includes(nameFinishConvocations)) {
                            dataReturn.push(nameFinishConvocations);
                        }
                    }
                }
            }
            return dataReturn;

        };

        // Change generic select filter camp
        const changeSelectGeneric = (
            value: string,
            index: number,
            key: string
        ) => {
            if (select && select[index]) {
                const name = select[index].name as keyof InterfaceDataUser;
                if (key === 'filter1') {
                    setFilter1(value)
                } else if (key === 'filter2') {
                    setFilter2(value)
                }
                !value ? requestSearch("") : requestSearch(value, name);


            }
        };
        //handleSelect1 chaneges
        const handleChangeSelect1 = (data: string) =>
            changeSelectGeneric(data, 0, "filter1");

        //handleSelect2 chaneges
        const handleChangeSelect2 = (data: string) =>
            changeSelectGeneric(data, 1, "filter2");

        //actions
        const handleAndSet = [
            {
                handle: handleChangeSelect1,
                data: filter1,
            },
            {
                handle: handleChangeSelect2,
                data: filter2,
            },
        ];



        // Debounce Helper
        const debounce = (func: Function, wait: number) => {
            let timeout: NodeJS.Timeout;
            return (...args: any[]) => {
                clearTimeout(timeout);
                timeout = setTimeout(() => func(...args), wait);
            };
        };

        const requestSearch = debounce(
            (searchedVal: string, key?: keyof InterfaceDataUser) => {
                const filteredRows = rows
                    ? rows.results.filter((row) => {
                        if (key) {
                            if (key === "role") {
                                const objRole = row[key] as InterfaceRoleUser;
                                const nameObject = rolesViews(objRole, t);
                                return nameObject
                                    .toLowerCase()
                                    .includes(searchedVal.toLowerCase());
                            } else if (key === "records") {
                                const arrayRecords = row[key] as InterfaceProjectLarge[];
                                for (const project of arrayRecords) {
                                    let nameFinishConvocations = t(
                                        "zAdmin.components.form.tableUser.w-conv"
                                    );
                                    if (project.project && project.project.announcement) {
                                        const dateAnnonun = moment(
                                            project.project.announcement.initialDate
                                        ).format("YYYY");
                                        const nameConvocations =
                                            project.project.announcement.name;

                                        nameFinishConvocations = `${dateAnnonun} - ${nameConvocations}`;

                                        if (
                                            nameFinishConvocations
                                                .toLowerCase()
                                                .includes(searchedVal.toLowerCase())
                                        ) {
                                            return true;
                                        }
                                    }
                                }
                                return false;
                            }
                        } else {
                            // Buscar en todos los campos
                            return Object.values(row).some((value) => {
                                if (typeof value === "string") {
                                    return value
                                        .toLowerCase()
                                        .includes(searchedVal.toLowerCase());
                                } else if (Array.isArray(value)) {
                                    // Manejar arrays como `records`
                                    return value.some((item) => {
                                        if (typeof item === "object") {
                                            return Object.values(item).some((subValue) => {
                                                if (typeof subValue === "string") {
                                                    return subValue
                                                        .toLowerCase()
                                                        .includes(searchedVal.toLowerCase());
                                                }
                                                return false;
                                            });
                                        }
                                        return false;
                                    });
                                } else if (typeof value === "object" && value !== null) {
                                    // Manejar objetos como `role`
                                    return Object.values(value).some((subValue) => {
                                        if (typeof subValue === "string") {
                                            return subValue
                                                .toLowerCase()
                                                .includes(searchedVal.toLowerCase());
                                        }
                                        return false;
                                    });
                                }
                                return false;
                            });
                        }
                    })
                    : [];

                handleChange({ field: "data", value: filteredRows });
            },
            300 // Tiempo de espera (300 ms)
        );



        const cancelSearch = () => {
            handleChange({ field: "search", value: "" });
            handleChange({ field: "filter1", value: "" });
            handleChange({ field: "filter2", value: "" });
            requestSearch(search);
        };

        if (rows && rows.count) {
            if (count != rows.count) {
                handleChange({ field: "count", value: rows.count });
            }
        }

        useEffect(() => {
            if (rows) {
                handleChange({ field: "data", value: rows.results });
                setIsDataReady(true); // Los datos están listos para ser mostrados
            }
        }, [rows]);

        return (
            <>
                <TableContainer
                    sx={{
                        height: "90%",
                        width: "90%",
                        margin: "auto",
                        marginTop: "2%",
                        padding: "2%",
                        maxHeight: props.maxHeight
                    }}
                >
                    {/* Title + reverse */}
                    <DoubleContainer
                        gridTemplateColumns="auto"
                        display="grid"
                        width="100%"
                    >
                        <NavbarLink paddingLeft="0" to="/">
                            {" "}
                            <ReturnIco />
                            {t("zAdmin.components.form.tableUser.back")}{" "}
                        </NavbarLink>
                        <TitleWrapper padding="0 0 2% 0">
                            {" "}
                            {title}{" "}
                        </TitleWrapper>
                    </DoubleContainer>

                    {/* Button actions  */}
                    {button}

                    {/* Filters */}
                    <DoubleContainer alingItems="baseline">
                        {/* Search  */}
                        <DoubleContainer>
                            <WrapperInput heigth={"100"} width="100%">
                                <TextField
                                    placeholder={title}
                                    size={"small"}
                                    onChange={(e) => {
                                        const value = e.target.value.trim();
                                        if (value === "") {
                                            cancelSearch();
                                        } else {
                                            requestSearch(value);
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </WrapperInput>
                        </DoubleContainer>

                        {/* Select filter  */}
                        <DoubleContainer>
                            {select &&
                                select.map((items, index) => {
                                    return (
                                        <AutocompleteComponent
                                            placeholder={
                                                items.placeholder as string
                                            }
                                            data={filterDataSelect(
                                                rows ? rows.results : [],
                                                items.name as unknown as keyof InterfaceDataUser
                                            )}
                                            handle={handleAndSet[index].handle}
                                            valueDefault={
                                                handleAndSet[index]
                                                    .data as string
                                            }
                                            key={`select-${index}`}
                                        />
                                    );
                                })}
                        </DoubleContainer>
                    </DoubleContainer>

                    {/* Content Tabla */}
                    {isLoading || isFetching || !isDataReady ? (
                        <Skeleton variant="rectangular" width="100%" height={400} />
                    ) : (
                        <TableContent colum={colum} rows={data} />
                    )}

                    {/* Paginate 
                    <TablePaginationGeneral
                        page={count <= 0 ? 0 : page}
                        rowsPerPage={count}
                        onChange={handleChange}
                        count={count}
                    />*/}
                    {/* Loading */}
                    <Loading open={isLoading || isFetching || !isDataReady} />
                </TableContainer>
            </>
        );
    }
);

const TableContent = memo(
    ({ colum, rows }: InterfaceTableContentFilter<InterfaceDataUser>) => {
        const [t, i18n] = useTranslation("global");
        //navigate
        const navigate = useNavigate();

        //ref
        const rowData = useRef({} as InterfaceDataUser);
        const textEmail = useRef(
            t("zAdmin.components.form.tableUser.info-text")
        );

        //
        const dispatch = useAppDispatch();
        const [openSendEmail, setOpenSendEmail] = useState(false);

        //api
        const [putDeactivate, { isLoading: isLoadingDeactivate }] =
            usePutDeactivateAdminMutation();
        const [postUserEmail, { isLoading: isLoadingEmail }] =
            usePostUserEmailMutation();

        const [deleteProfile, { isLoading: isLoadingDelete }] =
            useDeleteProfileMutation();


        const handleEditDataUser = () => {
            const id = rowData.current._id;
            return navigate(`/profile/${id}`);
        };

        const handleDeactivateUser = async () => {
            const { _id: id, active } = rowData.current;
            const textActive = active ? t("zAdmin.components.form.tableUser.inactive") : t("zAdmin.components.form.tableUser.active"); // Acá coloca la "t" para estos dos casos,
            try {

                const respDeactivate = await putDeactivate({
                    id,
                }).unwrap();

                if ("response" in respDeactivate) {
                    return dispatch(
                        activeAlertAction({
                            isActive: true,
                            isMode: "error",
                            // isText: `Ocurrió un error al ${textActive} un usuario`,
                            isText: `${t(
                                "zAdmin.components.form.tableUser.error-email"
                            )} ${textActive}`,
                        })
                    );
                }

                dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "success",
                        isText: `${t("zAdmin.components.form.tableUser.error-email")} 
                        ${textActive} 
                        ${t("zAdmin.components.form.tableUser.user-correctly")}`,
                    })
                );
            } catch (error) {
                return dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "error",
                        isText: `${t("zAdmin.components.form.tableUser.error-ocurred")} 
                        ${textActive} 
                        ${t("zAdmin.components.form.tableUser.user")}`,
                    })
                );
            }
        };

        const handleSendEmailUser = async () => {
            const { _id: id } = rowData.current;
            setOpenSendEmail(false);
            try {
                const respDeactivate = await postUserEmail({
                    id,
                    textInformation: textEmail.current,
                }).unwrap();

                if ("response" in respDeactivate) {
                    return dispatch(
                        activeAlertAction({
                            isActive: true,
                            isMode: "error",
                            isText: t(
                                "zAdmin.components.form.tableUser.error-email"
                            ),
                        })
                    );
                }

                dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "success",
                        isText: t(
                            "zAdmin.components.form.tableUser.email-success"
                        ),
                    })
                );
            } catch (error) {
                return dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "error",
                        isText: t(
                            "zAdmin.components.form.tableUser.error-email"
                        ),
                    })
                );
            }
        };


        //Nueva opción para borrar usuarios (experimental)
        //Evento que se hace al pulsarlo
        const handleDeleteUser = async () => {
            try {
                const { _id: id } = rowData.current;
                const respDeleteProfile = await deleteProfile({
                    id,
                }).unwrap();

                if ("response" in respDeleteProfile) {
                    return dispatch(
                        activeAlertAction({
                            isActive: true,
                            isMode: "error",
                            // isText: `Ocurrió un error al ${textActive} un usuario`,
                            isText: `${t("zAdmin.components.form.tableUser.delete-profile-error")} 
                                     ${"Error"}`,
                        })
                    );
                }

                dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "success",
                        isText: `${t("zAdmin.components.form.tableUser.delete-profile-success")}`,
                    })
                );

            } catch (error) {
                return dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "error",
                        isText: `${"Error"} 
                                 ${t("zAdmin.co<mponents.form.tableUser.delete-profile-error")}`,
                    })
                );
            }
        };

        const actionsMenu: InterfacePropsDataMenuUser = {
            editProjectAndRoles: () =>
                navigate(`/asignations/${rowData.current._id}`),
            editUser: () => handleEditDataUser(),
            off: () => handleDeactivateUser(),
            sendEmail: () => setOpenSendEmail(true),
            deleteUser: () => handleDeleteUser(),
        };


        const [isLoadingSorting, setLoadingSorting] = useState(false);
        //const [isLoadingDelete, setLoadingDelete] = useState(false);

        //Constantes para el tema del ordenador por campos
        const [sortField, setSortField] = useState("");
        const [sortDirection, setSortDirection] = useState("asc");
        const sortableColumns = ['name', 'status', 'role', 'createdAt', 'email'];

        const isColumnSortable = (columnName: string) => {
            //console.log(columnName);
            return sortableColumns.includes(columnName);
        };


        const compareStrings = (a: string, b: string, direction: string) => {
            if (a < b) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a > b) {
                return direction === 'asc' ? 1 : -1;
            }

            return 0;
        };

        const compareNumber = (a: number, b: number, direction: string) => {
            if (direction === 'asc') {
                return a - b
            } else {
                return b - a
            }
        }

        const compareRole = (a: string | InterfaceRoleUser, b: string | InterfaceRoleUser, direction: string) => {
            const arole: InterfaceRoleUser = a as InterfaceRoleUser;
            const brole: InterfaceRoleUser = b as InterfaceRoleUser;

            if (arole.name < brole.name) {
                return direction === 'asc' ? -1 : 1;
            }
            if (arole.name > brole.name) {
                return direction === 'asc' ? 1 : -1;
            }

            return 0;
        }


        const compareByField = (field: string, a: InterfaceDataUser, b: InterfaceDataUser, direction: string) => {

            switch (field) {
                case 'name':
                    return compareStrings(`${a.firstName} ${a.lastName}`, `${b.firstName} ${b.lastName}`, direction);
                case 'email':
                    return compareStrings(a.email ? a.email : '', b.email ? b.email : '', direction);
                case 'createdAt':
                    return compareStrings(a.createdAt || '', b.createdAt || '', direction);
                case 'role':
                    return compareRole(a.role || '', b.role || '', direction);
                case 'status':
                    return a.active === b.active ? 0 : (a.active ? 1 : -1);
                default:
                    return 0;
            }
        };

        const sortedData = [...rows].sort((a, b) => {
            return compareByField(sortField, a, b, sortDirection);
        });

        const renderSortIcon = (field: string) => {
            if (isColumnSortable(field) && field === sortField) {
                return sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />;
            }

            return null;
        };

        //Ordenado
        const handleSort = (field: string) => {
            setLoadingSorting(true);

            // Verifica si el mismo campo se está ordenando nuevamente para cambiar la dirección
            const direction = field === sortField && sortDirection === "asc" ? "desc" : "asc";

            setTimeout(() => {

                setSortField(field); // Actualiza el campo actualmente ordenado

                setSortDirection(direction);
                // Actualiza la dirección de ordenamiento

                setLoadingSorting(false);

            }, 500)


        };




        return (
            <>
                <div className="w-4 scrollbar-hide">
                    <Table
                        sx={{ minWidth: 500 }}
                        aria-label="custom pagination table"
                    >
                        <TableHead>
                            <TableRow>
                                {colum.map((items) => (
                                    <TableCell
                                        key={items.id}
                                        align={items.align}
                                        style={{
                                            minWidth: items.minWidth,
                                            fontWeight: items.fontWeight,
                                            cursor: isColumnSortable(items.id) ? 'pointer' : 'default',
                                        }}

                                        onClick={isColumnSortable(items.id) ? () => handleSort(items.id) : undefined}
                                    >
                                        {renderSortIcon(items.id)}
                                        {items.label}


                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody >
                            {sortedData &&
                                sortedData.map((row, index) => (
                                    <TableRow key={row.email}>
                                        <TableCell
                                            style={{ width: 160 }}
                                            align="center"
                                        >
                                            {`${row.firstName} ${row.lastName}`}
                                        </TableCell>
                                        <TableCell
                                            style={{ width: 160 }}
                                            align="center"
                                        >
                                            {row.email}
                                        </TableCell>
                                        <TableCell
                                            style={{ width: 160, padding: '12px', lineHeight: '1.5em' }}
                                            align="center"
                                        >
                                            {(() => {
                                                const validProjects = row.records
                                                    .map((projects) => projects as InterfaceProjectLarge)
                                                    .filter((project) => project.project && project.activeInProject);

                                                if (validProjects.length > 0) {
                                                    return validProjects.map((project, index) => {
                                                        const dateAnnonun = project.project.announcement
                                                            ? moment(project.project.announcement.initialDate).format("YYYY")
                                                            : "Sin fecha";
                                                        const nameProject = project.project.name;
                                                        const role = rolesViews(project.role, t); // Aquí va rol en español

                                                        return (
                                                            <div key={index} style={{ marginBottom: '8px', padding: '4px', borderBottom: '1px solid #ccc' }}>
                                                                <strong>{dateAnnonun}</strong> - <span style={{ color: '#007bff' }}>{nameProject}</span> - <em>{role}</em>
                                                            </div>
                                                        );
                                                    });
                                                } else {
                                                    // Si no hay proyectos válidos, mostrar un mensaje apropiado
                                                    const anyProjectDeleted = row.records.some(
                                                        (project) => !project.project
                                                    );
                                                    return (
                                                        <span style={{ color: anyProjectDeleted ? 'red' : '#555' }}>
                                                            {anyProjectDeleted ? "Proyecto borrado" : "Sin proyectos activos"}
                                                        </span>
                                                    );
                                                }
                                            })()}
                                        </TableCell>


                                        <TableCell
                                            style={{ width: 160 }}
                                            align="center"
                                        >
                                            {dateComplet(row.createdAt)}
                                        </TableCell>
                                        <TableCell
                                            style={{ width: 160 }}
                                            align="center"
                                        >
                                            {row.active ? t("zAdmin.components.form.tableUser.active") : t("zAdmin.components.form.tableUser.inactive")}
                                        </TableCell>
                                        <TableCell
                                            style={{ width: 160 }}
                                            align="center"
                                        >
                                            {typeof row.role === "string"
                                                ? ""
                                                : rolesViews(row.role, t)}
                                        </TableCell>
                                        <TableCell
                                            style={{ width: 160 }}
                                            align="center"
                                        >
                                            {(() => {
                                                const convocationsSet = new Set();
                                                const validProjects = row.records
                                                    .map((projects) => projects as InterfaceProjectLarge)
                                                    .filter((project) => {
                                                        if (project.project && project.project.announcement) {
                                                            const dateAnnonun = moment(project.project.announcement.initialDate).format("YYYY");
                                                            const nameConvocations = project.project.announcement.name;
                                                            const nameFinishConvocations = `${dateAnnonun} - ${nameConvocations}`;

                                                            // Añadir al set si no está ya incluido
                                                            if (!convocationsSet.has(nameFinishConvocations)) {
                                                                convocationsSet.add(nameFinishConvocations);
                                                                return true;
                                                            }
                                                        }
                                                        return false;
                                                    });

                                                if (convocationsSet.size > 0) {
                                                    // Renderizar las convocatorias únicas
                                                    return Array.from(convocationsSet).map((nameFinishConvocations, index) => (
                                                        <span key={index}>
                                                            {nameFinishConvocations as string}
                                                            <br />
                                                        </span>
                                                    ));
                                                } else {
                                                    // Si no hay proyectos válidos, mostrar "Proyecto borrado"
                                                    const anyProjectDeleted = row.records.some(
                                                        (project) => !project.project
                                                    );
                                                    return (
                                                        <span>
                                                            {anyProjectDeleted ? "Proyecto borrado" : "Sin convocatorias válidas"}
                                                        </span>
                                                    );
                                                }
                                            })()}
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                width: 160,
                                                cursor: "pointer"
                                            }}
                                            align="center"
                                            onClick={() => {
                                                rowData.current = row;
                                            }}
                                        >
                                            <MoreComponent
                                                items={dataMenuUser(t, actionsMenu)}
                                                props={{}}
                                                key={`moreComponent-${row.email}`}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>

                    <ModalGeneric
                        handleClose={() => setOpenSendEmail(false)}
                        isOpen={openSendEmail}
                    >
                        <DialogContent>
                            <WrapperInput>
                                <LabelInput htmlFor={"sendEmail"}>
                                    {t(
                                        "zAdmin.components.form.tableUser.send-email"
                                    )}
                                </LabelInput>
                                <SubLabelInput>
                                    {t(
                                        "zAdmin.components.form.tableUser.send-email-text"
                                    )}
                                </SubLabelInput>
                                <StyledMultiline
                                    id="outlined-multiline-static"
                                    onChange={(e) => {
                                        textEmail.current = e.target.value;
                                    }}
                                    style={{ width: "100%" }}
                                    multiline
                                    rows={4}
                                    defaultValue={t(
                                        "zAdmin.components.form.tableUser.info-text"
                                    )}
                                />
                            </WrapperInput>
                        </DialogContent>
                        <DialogActions style={{ padding: "2%" }}>
                            <ButtonComponent
                                className="btn-hover"
                                type="button"
                                onClick={() => handleSendEmailUser()}
                            >
                                {t("zAdmin.components.form.tableUser.send")}
                            </ButtonComponent>
                        </DialogActions>
                    </ModalGeneric>

                </div>

                {/* Loading */}
                <Loading open={isLoadingDeactivate || isLoadingEmail || isLoadingSorting || isLoadingDelete} />
            </>
        );
    }
);
