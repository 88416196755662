//modules react
import { useEffect, useState, ChangeEvent } from "react";

import {
    Skeleton,
    Stack,
    DialogContent,
    DialogActions,
    TextField,
} from "@mui/material";

//style
import {
    Container,
    ButtonComponent,
    TitleWrapper,
    ContainerGeneral,
    ReturnIco
} from "../../../styles/globalComponents";
import {
    Wrapper,
    DoubleContainer,
    SubTitleWrapperGeneral,
} from "../../../styles/globalComponents";
import { NavbarLink } from "../../../styles/nav";
import { EditIcon } from "../../styles/project";
import { LabelInput, WrapperInput } from "../../../zUsers/styles/Input";

//translate
import { useTranslation } from "react-i18next";
//components
import { Archivo } from "../archivo";
import { ModalGeneric } from "../../../components/modalGeneric";
import { BasicModal } from "../../../components/basicModal";
import { Loading } from "../../../components/loading";
import { AlertComponent } from "../../../components/alert";

//api
import {
    useGetProjectsQuery,
    usePutChangeNameProjectMutation,
} from "../../api/project";

//slices
import { selectUser } from "../../../zUsers/slices/auth";

//hooks
import { useAppSelector } from "../../../hooks/redux";

//interfaces
import { InterfaceHomeStyle } from "../../interfaces/project";
import { InterfaceRoleUser } from "../../../interfaces/user";

//data
import { dataAlumno } from "../../data/alumno";
import { dataAdmin } from "../../data/admin";
import { dataRepresentative } from "../../data/representative";

import {
    alumnoComponentAction,
    baseFileCheck,
    baseFileSuplementary,
} from "../../data/project";

//utils
import { dateComplet, pathFile } from "../../../utils/utils";
import { useParams } from "react-router-dom";
import { FormGroup, Typography, Switch, FormControlLabel } from "@material-ui/core";

export const ProjectRepresentantive = ({ props }: InterfaceHomeStyle) => {
    const [t, i18n] = useTranslation("global");
    //user
    const user = useAppSelector(selectUser);
    const userRole = user.role as InterfaceRoleUser;

    const { idPA } = useParams();

    //project
    const project = user.records[user.records.length - 1];

    const idP = idPA
        ? idPA
        : "project" in project
            ? typeof project.project === "string"
                ? project.project
                : project.project._id
            : project._id;

    const editNameProject =
        userRole.name === "admin"
            ? true
            : userRole.name === "representative"
                ? true
                : false;
    //apis
    const { isLoading, isError, data } = useGetProjectsQuery({
        id: idP,
    });
    const [putChangeName, { isLoading: isLoadingRenameProject }] =
        usePutChangeNameProjectMutation();

    //state
    const [openInfo, setOpenInfo] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [isErrorModal, setIsErrorModal] = useState(false);
    const [nameProject, setNameProject] = useState("");
    const [isSuccessModal, setIsSuccessModal] = useState(false);

    //data
    const {
        ComponentAction: Check,
        ComponentIcon: Icon,
        props: propsCheck,
    } = baseFileCheck(t);

    const {
        ComponentAction: ComponentAction2,
        ComponentIcon: ComponentIcon2,
        props: props2,
        text1: tex12,
        text2: text22,
        text3: text32,
    } = baseFileSuplementary(t);

    const [openForm, setOpenModalForm] = useState(false);

    //useEffect
    useEffect(() => {
        if (data) {
            setNameProject(data.name || "");
            setIsGroupWork(data.isGroupWork || false);
            setHasTutor(data.hasTutor || false);
        }
    }, [data]);

    //handle
    // Función para renombrar el proyecto
   // Función para renombrar el proyecto y actualizar los switches
   const handleRenameProject = async () => {
    const { _id } = project;

    if (nameProject.length < 3) {
        return setOpenError(true);
    }

    if (editNameProject) {
        try {
            const responseRenameFile = await putChangeName({
                user_id: userRole._id,
                id: _id,
                name: nameProject,
                isGroupWork: !!isGroupWork,  
                hasTutor: !!hasTutor,      
            }).unwrap();

            // Actualiza el subtítulo con los nuevos valores
            setNameProject(responseRenameFile.name); // actualiza el título
            setIsGroupWork(responseRenameFile.isGroupWork);
            setHasTutor(responseRenameFile.hasTutor);

            setIsSuccessModal(true);
        } catch (error) {
            setIsErrorModal(true);
        }
    } else {
        setOpenInfo(true);
    }

    setOpenModalForm(false);
};


    // Añade los estados para los switches
    const [isGroupWork, setIsGroupWork] = useState(data ? data.isGroupWork : false);
    const [hasTutor, setHasTutor] = useState(data ? data.hasTutor : false);

    const handleGroupWorkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsGroupWork(event.target.checked);
    };

    const handleTutorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHasTutor(event.target.checked);
    };


    const handleChangeRename = (field: string, value: string | boolean) => {
        if (field === "nameProject") {
            setNameProject(value as string);
        } else if (field === "isGroupWork") {
            setIsGroupWork(value as boolean);
        } else if (field === "hasTutor") {
            setHasTutor(value as boolean);
        }
    };


    const dataRender =
        userRole.name === "representative"
            ? dataRepresentative
            : userRole.name === "admin"
                ? dataAdmin
                : dataAlumno;

    return (
        <Container width={props.width} heigth={props.heigth}>
            <Wrapper>
                <DoubleContainer
                    gridTemplateColumns="auto"
                    display="grid"
                    width="100%">

                    {(userRole.name === "admin") && (
                        <NavbarLink paddingLeft="0" to="#" onClick={() => window.history.back()}>
                            <ReturnIco />
                            {t("zProjects.components.form.projectRepresentative.back")}
                        </NavbarLink>
                    )}

                    <TitleWrapper>
                        {" "}
                        {(isLoading || isError) && (
                            <Skeleton width={"95%"} variant="text" />
                        )}
                        {data && data.name}

                        {/* Subtítulo con información del proyecto */}
                        {data && (
                            <Typography variant="subtitle1" color="textSecondary" component="div">
                                {data.isGroupWork ? t("zProjects.components.form.projectRepresentative.group-work") : t("zProjects.components.form.projectRepresentative.individual-work")} -{" "}
                                {data.hasTutor ? t("zProjects.components.form.projectRepresentative.with-tutor") : t("zProjects.components.form.projectRepresentative.without-tutor")}
                            </Typography>
                        )}
                    </TitleWrapper>
                    {editNameProject && (
                        <SubTitleWrapperGeneral
                            onClick={() => setOpenModalForm(true)}
                            padding="0 5% 0% 5%"
                            style={{
                                cursor: "pointer"
                            }}

                        >
                            {(isLoading || isError) && (
                                <Skeleton width={"20%"} variant="text" />
                            )}
                            {data && (
                                <>
                                    <EditIcon />
                                    {t(
                                        "zProjects.components.form.projectRepresentative.edit"
                                    )}
                                </>
                            )}
                        </SubTitleWrapperGeneral>
                    )}
                </DoubleContainer>
                <DoubleContainer width="100%" padding="5%">
                    {data && (
                        <>
                            <NavbarLink
                                textDecoration="none"
                                margin="0"
                                to={`/member/${idP}`}
                            >
                                <ButtonComponent
                                    className="btn-hover"
                                    type="button"
                                >
                                    {t(
                                        "zProjects.components.form.projectRepresentative.see-members-project"
                                    )}
                                </ButtonComponent>{" "}
                            </NavbarLink>
                            <NavbarLink
                                textDecoration="none"
                                margin="0"
                                to={`/logs/${idP}/${data.name}`}
                            >
                                <ButtonComponent
                                    className="btn-hover"
                                    type="button"
                                >
                                    {t(
                                        "zProjects.components.form.projectRepresentative.change-history"
                                    )}
                                </ButtonComponent>
                            </NavbarLink>
                        </>
                    )}
                    {(isLoading || isError) && (
                        <>
                            <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={60}
                            />

                            <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={60}
                            />
                        </>
                    )}
                </DoubleContainer>
                <ContainerGeneral width="90%;" heigth={props.heigth}>
                    {(isLoading || isError) && (
                        <Skeleton width={"20%"} variant="text" />
                    )}
                    <TitleWrapper padding="0">
                        {data && "Archivos"}
                    </TitleWrapper>
                    {(isLoading || isError) && (
                        <Stack spacing={2}>
                            <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={110}
                            />
                            <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={100}
                            />
                            <Skeleton
                                variant="rectangular"
                                width={"100%"}
                                height={90}
                            />
                        </Stack>
                    )}
                    {data &&
                        dataRender(data, t).map(
                            (
                                {
                                    props: propsRender,
                                    ComponentIcon,
                                    ComponentAction,
                                    text1,
                                    text2,
                                    text3,
                                    typeFile: typeFile1,
                                    data: dataFile,
                                },
                                index
                            ) => {
                                return (
                                    <Archivo
                                        props={{
                                            ...propsRender,
                                            imgWidth: props.imgWidth,
                                        }}
                                        ComponentIcon={ComponentIcon}
                                        ComponentAction={ComponentAction}
                                        text1={text1}
                                        text2={text2}
                                        text3={text3}
                                        data={dataFile}
                                        typeFile={typeFile1}
                                        key={index}
                                    />
                                );
                            }
                        )}
                    {data &&
                        data.supplementaryFiles.map(
                            ({ key, date, url, uploadedBy }) => {
                                return (
                                    <Archivo
                                        props={{
                                            ...propsCheck,
                                            imgWidth: props.imgWidth,
                                        }}
                                        ComponentIcon={Icon}
                                        ComponentAction={
                                            userRole.name === "representative"
                                                ? Check
                                                : alumnoComponentAction(t)
                                        }
                                        text1={pathFile(key)}
                                        text2={t("zProjects.components.form.projectRepresentative.uploader") + (dateComplet(date))}
                                        key={date}
                                        typeFile={"check"}
                                        data={{ key, date, url, uploadedBy }}
                                    />
                                );
                            }
                        )}
                    {data && data.supplementaryFiles.length < 8 && (
                        <Archivo
                            props={{
                                ...props2,
                                imgWidth: props.imgWidth,
                            }}
                            ComponentIcon={ComponentIcon2}
                            ComponentAction={
                                ComponentAction2
                            }
                            text1={tex12}
                            text2={text22}
                            text3={text32}
                            typeFile={"suplementary"}
                            key={
                                tex12 +
                                text22
                            }
                        />
                    )}
                </ContainerGeneral>
            </Wrapper>

            {/* Modal para renombrar el proyecto */}
            <ModalGeneric isOpen={openForm} handleClose={() => setOpenModalForm(false)}>
                <DialogContent>
                    {/* Campo de texto para cambiar el nombre */}
                    <WrapperInput>
                        <LabelInput>
                            {t("zProjects.components.form.projectRepresentative.change-name-project")}
                        </LabelInput>
                        <TextField
                            margin="dense"
                            id="name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={nameProject}
                            onChange={(e) => handleChangeRename("nameProject", e.target.value)}
                        />
                    </WrapperInput>

                    {/* Switches para "Trabajo en grupo" y "Con tutor" */}
                    <FormGroup row style={{ marginTop: "1rem" }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isGroupWork}
                                    onChange={(e) => handleChangeRename("isGroupWork", e.target.checked)}
                                />
                            }
                            label={
                                isGroupWork
                                    ? t("zProjects.components.form.projectRepresentative.group-work")
                                    : t("zProjects.components.form.projectRepresentative.individual-work")
                            }
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={hasTutor}
                                    onChange={(e) => handleChangeRename("hasTutor", e.target.checked)}
                                />
                            }
                            label={
                                hasTutor
                                    ? t("zProjects.components.form.projectRepresentative.with-tutor")
                                    : t("zProjects.components.form.projectRepresentative.without-tutor")
                            }
                        />
                    </FormGroup>
                </DialogContent>

                <DialogActions>
                    <DoubleContainer width="100%" padding="0 3% 3% 3%">
                        <ButtonComponent
                            className="btn-hover"
                            type="button"
                            onClick={() => setOpenModalForm(false)}
                        >
                            {t("zProjects.components.form.projectRepresentative.cancel")}
                        </ButtonComponent>
                        <ButtonComponent
                            className="btn-hover"
                            type="button"
                            onClick={() => handleRenameProject()}
                        >
                            {t("zProjects.components.form.projectRepresentative.rename")}
                        </ButtonComponent>
                    </DoubleContainer>
                </DialogActions>

                <Loading open={isLoadingRenameProject} />
            </ModalGeneric>

            {/* Alerts response api */}
            <AlertComponent
                verify={"error"}
                open={isErrorModal}
                onClose={() => setIsErrorModal(false)}
                text={t(
                    "zProjects.components.form.projectRepresentative.error-data-load"
                )}
            />
            <AlertComponent
                verify={"success"}
                open={isSuccessModal}
                onClose={() => setIsSuccessModal(false)}
                text={t("zProjects.components.form.projectRepresentative.edit")}
            />

            <BasicModal
                open={openInfo}
                setOpenModal={setOpenInfo}
                text={[
                    t(
                        "zProjects.components.form.projectRepresentative.action-admin"
                    ),
                ]}
                title={t(
                    "zProjects.components.form.projectRepresentative.notice"
                )}
            />
            <BasicModal
                open={openError}
                setOpenModal={setOpenError}
                text={[
                    t(
                        "zProjects.components.form.projectRepresentative.three-characters"
                    ),
                ]}
                title={t(
                    "zProjects.components.form.projectRepresentative.error"
                )}
            />
        </Container>
    );
};
