//modules react
import { useState, useEffect } from "react";
import { Link as RouteLink, useParams, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Link from "@mui/material/Link";
import { Skeleton, Stack } from "@mui/material";
import moment from 'moment'


//style
import {
    Container,
    TitleWrapper,
    ReturnIco,
} from "../../../styles/globalComponents";
import {
    Wrapper,
    DoubleContainer,
    ButtonComponent,
    WrapperButton,
} from "../../../styles/globalComponents";

//components
import { InputComponent } from "../Input";
import { SelectComponent } from "../../../components/select";
import { Loading } from "../../../components/loading";
import { AlertComponent } from "../../../components/alert";
import { SkeletonInputComponent } from "../../../components/skeletonInput";
import { CalendarComponent } from "../../../components/calendar";

//translate
import { useTranslation } from "react-i18next";
//interace
import {
    InterfaceInputProfile,
    InterfaceProfileProps,
    InterfaceRequestPutProfile,
} from "../../interfaces/profile";

import { InterfaceStyle } from "../../../interfaces/interface";

///validators
import { schemaProfile } from "../../validators/schemaProfile";

//data
import { dataCountryArray } from "../../data/register";
import {
    dataProfile,
    dataPassword,
    INIT_DATA_USER_FORM_PROFILE,
} from "../../data/profile";

//apis
import {
    usePutChangePasswordMutation,
    usePutProfileMutation,
    usePutDeactivateMutation,
} from "../../api/auth";
import { useGetUsersIDQuery } from "../../../zAdmin/api/users";

//hoks
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";

//slices
import { logoutAction, putUserAction, selectUser } from "../../slices/auth";


export const FormProfile = (props: InterfaceStyle) => {
    const [t, i18n] = useTranslation("global");
    //States
    const [password, setPassword] = useState(false);
    const [isErrorModal, setIsErrorModal] = useState(false);
    const [isSuccessModal, setIsSuccessModal] = useState(false);
    const [isInfoModal, setIsInfoModal] = useState(false);

    // routers
    const { idUserProfile } = useParams();
    const navegate = useNavigate();

    //users
    const user = useAppSelector(selectUser);

    //Event
    const dispatch = useAppDispatch();

    //apis
    const { data, refetch, isError, isFetching } = useGetUsersIDQuery({
        id: idUserProfile || user._id,
    });
    const [putProfile, { isLoading: isLoadingProfile }] =
        usePutProfileMutation();

    const [putPassword, { isLoading: isLoadingPassword }] =
        usePutChangePasswordMutation();

    const [putDeactivate, { isLoading: isLoadingDeactivate }] =
        usePutDeactivateMutation();



    //Forms
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset,
    } = useForm<InterfaceProfileProps>({
        defaultValues: INIT_DATA_USER_FORM_PROFILE,
        resolver: yupResolver(schemaProfile(t)),
    });
    //handles
    const handleSumbitProfile = async (dataSend: InterfaceProfileProps) => {
        if (data) {

            const {
                firstName: firstNameSend,
                lastName: lastNameSend,
                phone: phoneSend,
                street: streetSend,
                grade: gradeSend,
                province: provinceSend,
                country: countrySend,
                zipCode: zipCodeSend,
                password,
                birthday: birthdaySend,
                schoolCenter: schoolCenterSend,
                schoolEmail: schoolEmailSend,
                population: populationSend,
                roleType: roleTypeSend,
            } = dataSend;

            const {
                firstName,
                lastName,
                phone,
                street,
                grade,
                province,
                country,
                zipCode,
                birthday,
                population,
                schoolCenter,
                schoolEmail,
                roleType,

            } = data;

            if (
                firstNameSend != firstName ||
                lastNameSend != lastName ||
                phoneSend != phone ||
                streetSend != street ||
                gradeSend != grade ||
                provinceSend != province ||
                countrySend != country ||
                zipCodeSend != zipCode ||
                birthday != birthdaySend ||
                population != populationSend ||
                schoolCenter != schoolCenterSend ||
                schoolEmail != schoolEmailSend ||
                roleType != roleTypeSend
            ) {
                const requestPut: InterfaceRequestPutProfile = {
                    ...dataSend,
                    _id: data._id,
                };
                try {
                    const respProfile = await putProfile(requestPut).unwrap();
                    refetch();
                    setIsSuccessModal(true);
                    if (respProfile._id === user._id) {
                        dispatch(putUserAction(respProfile));
                    }
                } catch (error) {
                    setIsErrorModal(true);
                }
            } else if (password) {
                console.log(password);
                const requestPassword = {
                    password: password as string,
                    id: data._id,
                };
                try {
                    const respProfile = await putPassword(
                        requestPassword
                    ).unwrap();
                    setIsSuccessModal(true);
                } catch (error) {
                    setIsErrorModal(true);
                }
            } else {
                setIsInfoModal(true);
            }
        }
    };

    const handleSumbitDeactivate = async () => {
        try {
            const respDeactivate = await putDeactivate({
                id: data ? data._id : "",
            }).unwrap();

            if ("response" in respDeactivate) {
                return setIsErrorModal(true);
            }
            if (data) {
                if (data._id === user._id) {
                    dispatch(logoutAction());
                    navegate("/auth/signin");
                }
            }
            setIsSuccessModal(true);
        } catch (error) {
            setIsErrorModal(true);
        }
    };

    // Función para capitalizar la primera letra
    const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

    const roleTranslation =
        data &&
            typeof data.role === "object" &&
            data.role.translation &&
            data.role.translation[0] &&
            data.role.translation[0].name
            ? ` - ${capitalize(data.role.translation[0].name)}`
            : "";
            
    //Efect
    useEffect(() => {
        if (data) {
            const {
                email,
                firstName,
                lastName,
                phone,
                street,
                grade,
                province,
                country,
                zipCode,
                schoolCenter,
                schoolEmail,
                roleType,
                population,
                birthday
            } = data;

            reset({
                email,
                firstName,
                lastName,
                phone,
                street,
                grade,
                province,
                country,
                zipCode,
                schoolCenter,
                schoolEmail,
                roleType,
                population,
                birthday
            });
        } else if (isError) {
            setIsErrorModal(true);
        }
    }, [data, isError]);

    return (
        <Container width={props.width} heigth={props.heigth}>
            <Wrapper>
                {data ? (
                    <>
                        <div style={{ display: "grid", width: "100%" }}>
                            <TitleWrapper>
                                <RouteLink to="#" onClick={() => window.history.back()}>
                                    <Link color="black" underline="hover">
                                        <ReturnIco /> {t("zUsers.components.form.profile.back")}
                                    </Link>
                                </RouteLink>
                            </TitleWrapper>
                            <TitleWrapper>
                                {t("zUsers.components.form.profile.ur-account")}
                                {roleTranslation}
                            </TitleWrapper>

                        </div>
                        <form
                            noValidate
                            onSubmit={handleSubmit((dataSend) =>
                                handleSumbitProfile(dataSend)
                            )}
                        >
                            {dataProfile(t, errors).map((items) => {
                                if (items instanceof Array<InterfaceInputProfile>) {
                                    return (
                                        <DoubleContainer>
                                            {items.map(
                                                ({
                                                    registerLabel,
                                                    errors,
                                                    subLabel,
                                                    label,
                                                    type,
                                                    mask,
                                                    placeholder,
                                                    value,
                                                    disable,
                                                }) => {


                                                    if (
                                                        registerLabel ===
                                                        "country"
                                                    ) {
                                                        return (
                                                            <SelectComponent<InterfaceProfileProps>
                                                                label={label}
                                                                data={
                                                                    dataCountryArray
                                                                }
                                                                formControl={{
                                                                    control,
                                                                    name: "country",
                                                                }}
                                                                placeholder={""}
                                                            />
                                                        );
                                                    } else {
                                                        return (
                                                            <InputComponent
                                                                {...register(
                                                                    registerLabel
                                                                )}
                                                                errors={errors}
                                                                label={label}
                                                                subLabel={
                                                                    subLabel
                                                                }
                                                                type={type}
                                                                mask={mask}
                                                                placeholder={
                                                                    placeholder
                                                                }
                                                                disabled={
                                                                    disable
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        );
                                                    }
                                                }
                                            )}
                                        </DoubleContainer>
                                    );
                                } else {
                                    if (items.registerLabel === "birthday") {
                                        return (
                                            <CalendarComponent<InterfaceProfileProps>
                                                label={items.label}
                                                plataform={
                                                    props.responsive ||
                                                    "desktop"
                                                }
                                                formControl={{
                                                    control,
                                                    name: "birthday",
                                                }}
                                            />
                                        );
                                    } else if (items.registerLabel === 'email') {
                                        //console.log("entro aqui: " + items.registerLabel)

                                        return (<InputComponent
                                            {...register(items.registerLabel)}
                                            errors={items.errors}
                                            label={items.label}
                                            subLabel={items.subLabel}
                                            placeholder={items.placeholder}
                                            type={items.type}
                                            mask={items.mask}
                                            disabled={true}
                                        />);
                                    } else {

                                        return (
                                            <InputComponent
                                                {...register(items.registerLabel)}
                                                errors={items.errors}
                                                label={items.label}
                                                subLabel={items.subLabel}
                                                placeholder={items.placeholder}
                                                type={items.type}
                                                mask={items.mask}
                                            />
                                        );
                                    }
                                }
                            })}
                            <WrapperButton>
                                <ButtonComponent
                                    className="btn-hover"
                                    onClick={() => setPassword(!password)}
                                    type="button"
                                >
                                    {t("zUsers.components.form.profile.modify")}
                                </ButtonComponent>
                            </WrapperButton>
                            {password &&
                                dataPassword(t, errors).map((items) => {
                                    return (
                                        <InputComponent
                                            {...register(items.registerLabel)}
                                            errors={items.errors}
                                            label={items.label}
                                            subLabel={items.subLabel}
                                            placeholder={items.placeholder}
                                            type={items.type}
                                            mask={items.mask}
                                        />
                                    );
                                })}

                            <WrapperButton>
                                <ButtonComponent
                                    className="btn-hover"
                                    type="button"
                                    onClick={() => handleSumbitDeactivate()}
                                >
                                    {t("zUsers.components.form.profile.deactivate-account")}
                                </ButtonComponent>
                            </WrapperButton>
                            <WrapperButton>
                                <ButtonComponent className="btn-hover">
                                    {t("zUsers.components.form.profile.save-changes")}
                                </ButtonComponent>
                            </WrapperButton>
                        </form>
                    </>
                ) : (
                    <>
                        <div style={{ display: "grid", width: "100%" }}>
                            <TitleWrapper>
                                <Skeleton width={"20%"} variant="text" />
                            </TitleWrapper>

                            <TitleWrapper>
                                <Skeleton width={"20%"} variant="text" />
                            </TitleWrapper>
                        </div>
                        <form>
                            <SkeletonInputComponent />
                            <DoubleContainer>
                                <SkeletonInputComponent />
                                <SkeletonInputComponent />
                            </DoubleContainer>
                            <SkeletonInputComponent />
                            <SkeletonInputComponent />
                            <DoubleContainer>
                                <SkeletonInputComponent />
                                <SkeletonInputComponent />
                            </DoubleContainer>
                            <SkeletonInputComponent />
                            <Stack spacing={2}>
                                <Skeleton
                                    variant="rectangular"
                                    width={"100%"}
                                    height={60}
                                />
                                <Skeleton
                                    variant="rectangular"
                                    width={"100%"}
                                    height={60}
                                />
                                <Skeleton
                                    variant="rectangular"
                                    width={"100%"}
                                    height={60}
                                />
                            </Stack>
                        </form>
                    </>
                )}
            </Wrapper>

            {/* Loading page */}
            <Loading
                open={
                    isLoadingProfile ||
                    isLoadingPassword ||
                    isLoadingDeactivate ||
                    isFetching
                }
            />

            {/* Alert Erros */}
            <AlertComponent
                verify={"error"}
                open={isErrorModal}
                onClose={() => setIsErrorModal(false)}
                text={`${t("zUsers.components.form.profile.save-changes")}`}
            />
            {/* Alert Success */}
            <AlertComponent
                verify={"success"}
                open={isSuccessModal}
                onClose={() => setIsSuccessModal(false)}
                text={`${t("zUsers.components.form.profile.update-correctly")}`}
            />
            {/* Alert Info */}
            <AlertComponent
                verify={"info"}
                open={isInfoModal}
                onClose={() => setIsInfoModal(false)}
                text={`${t("zUsers.components.form.profile.fill-update")}`}
            />
        </Container>
    );
};
