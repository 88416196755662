import { memo } from 'react'

import { StyledMultiline } from "../styles/materialUi";
import { WrapperInput } from "../../zUsers/styles/Input";
import { InterfaceSliderForm } from "../interfaces/score";

export const UseFormControl = memo(({
    value,
    name,
    handleChange,
    disable
}: InterfaceSliderForm) => {
    return (
        <WrapperInput>
            <StyledMultiline
                id="outlined-multiline-static"
                style={{
                    width: "100%",
                    height: "300px", // Ajusta la altura deseada
                    overflowY: "auto", // Permite scroll vertical
                    wordBreak: "normal", // Evita cortes raros en palabras
                    overflowWrap: "break-word", // Ajusta palabras largas
                    whiteSpace: "pre-wrap", // Mantiene los saltos de línea
                }}
                multiline
                rows={12} // Aumenta el número de filas para hacerlo más alto
                value={value}
                disabled={disable}
                onChange={(e) =>
                    handleChange({ field: name, value: e.target.value })
                }
            />
        </WrapperInput>
    );
});