//React Modules
import { useState, MouseEvent, ChangeEvent, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow,
    Paper,
    TableHead,
    DialogContent,
    DialogActions,
    TextField,
} from "@mui/material";


// Interfaces
import { InterfaceTableBase } from "../../interfaces/table";

//Components
import { TablePaginationActions } from "../paginate";
import {
    ButtonComponent,
    DoubleContainer,
    TitleWrapper,
} from "../../../styles/globalComponents";
import { InputAdornment, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import { WrapperInput } from "../../../zUsers/styles/Input";
import { NavbarLink } from "../../../styles/nav";

import { ReturnIco } from "../../../styles/globalComponents";

import { useAppDispatch } from "../../../hooks/redux";
import {
    InterfaceConvocationsTableBase,
    InterfaceModalConvocations,
} from "../../interfaces/convocations";
import { MoreComponent } from "../more";
import {
    dataMenuConvocations,
    INIT_DATA_TABLE_CONVOCATIONS,
} from "../../data/convocations";
import { ModalGeneric } from "../../../components/modalGeneric";
import { CalendarComponent } from "../../../components/calendar";
import { InputComponent } from "../../../zUsers/components/Input";

import { useForm } from "react-hook-form";
//translate
import { useTranslation } from "react-i18next";

import { INIT_DATA_CONVOCATIONS } from "../../data/convocations";
import {
    useDeleteAnnouncementsMutation,
    useGetListAnnouncementsQuery,
    usePostAnnouncementsMutation,
    usePutAnnouncementsAODMutation,
    usePutAnnouncementsMutation,
} from "../../api/announcements";
import { Loading } from "../../../components/loading";
import { activeAlertAction } from "../../../slices/alerts";
import { schemaConvocations } from "../../validators/convocations";

export const TableConvocationsForm = ({
    props,
    title,
    colum,
}: InterfaceTableBase): JSX.Element => {
    const [t, i18n] = useTranslation("global");

    //apis
    const { isLoading, data: rows } = useGetListAnnouncementsQuery();

    const [postAnnouncements, { isLoading: isLAnoun }] =
        usePostAnnouncementsMutation();
    const [putAnnouncements, { isLoading: isPutAnoun }] =
        usePutAnnouncementsMutation();

    const [deleteAnnoucement, { isLoading: isDelAnoun }] =
        useDeleteAnnouncementsMutation();


    const [putAnnouncementsAOD, { isLoading: isActiveAnoun }] =
        usePutAnnouncementsAODMutation();

    useEffect(() => {
        if (rows) {
            setData(rows);
        }
    }, [rows]);

    //state pages
    const [page, setPage] = useState(0);
    //states RowPages
    const [rowsPerPage, setRowsPerPage] = useState(25);
    //state data rows
    const [data, setData] = useState(INIT_DATA_TABLE_CONVOCATIONS);

    //Row data
    const [rowData, setRowData] = useState<InterfaceConvocationsTableBase>(
        {} as InterfaceConvocationsTableBase
    );

    //state search filter
    const [searched, setSearched] = useState("");

    //BUTTONS

    const [openCreateConvocations, setOpenCreateConvocations] = useState(false);
    const [editConvocations, setEditConvocations] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        reset,
    } = useForm<InterfaceModalConvocations>({
        defaultValues: INIT_DATA_CONVOCATIONS,
        resolver: yupResolver(schemaConvocations(t))
    });

    const handleClickOpenCreateConvocations = (
        event: MouseEvent<HTMLButtonElement> | null
    ) => {
        reset();
        setEditConvocations(false);
        setOpenCreateConvocations(true);
    };

    //handle close
    const handleCloseCreateConvocations = () =>
        setOpenCreateConvocations(false);

    //Dispath redux
    const dispatch = useAppDispatch();

    //filter search all keys
    const requestSearch = (
        searchedVal: string,
        key?: keyof InterfaceConvocationsTableBase
    ) => {
        const filteredRows = rows
            ? rows.filter((row) => {
                if (key) {
                    //   if (key === "jurors") {
                    //       if (row.jurors) {
                    //           for (const itemsRecords of row.jurors) {
                    //               return itemsRecords.firstName
                    //                   .toLowerCase()
                    //                   .includes(searchedVal.toLowerCase());
                    //           }
                    //       }
                    //   } else if (key === "announcement") {
                    //       const dateAndName =
                    //           row.announcement.name +
                    //           " " 
                    //       return dateAndName
                    //           .toLowerCase()
                    //           .includes(searchedVal.toLowerCase());
                    //   }
                } else {
                    for (const [key, value] of Object.entries(
                        row
                    )) {
                        let valueString = value as string;
                        if (key === '_id') {
                            continue
                        } else if (key === '__v') {
                            continue
                        } else if (key === 'active') {

                            valueString = value ? t("zAdmin.components.form.tableConvocations.active") : t("zAdmin.components.form.tableConvocations.inactive")
                        }

                        if (key != 'name') {
                            valueString = transformDate(valueString)
                        }
                        if (valueString
                            .toLowerCase()
                            .includes(searchedVal.toLowerCase())) {
                            return true
                        } else {
                            continue
                        }
                    }

                    return false

                }

            })
            : [];
        setData(filteredRows)
    };


    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0
            ? Math.max(0, (1 + page) * rowsPerPage - (rows ? rows.length : 0))
            : 0;

    const handleChangePage = (
        event: MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClickGetRow = (rowUser: InterfaceConvocationsTableBase) => {
        setRowData(rowUser);
    };

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteRowData, setDeleteRowData] = useState<InterfaceConvocationsTableBase | null>(null);


    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
        setDeleteRowData(null); // Limpiar la fila seleccionada
    };



    const handleConfirmDelete = async () => {
        if (deleteRowData) {
            try {

                // Aquí realiza la llamada a la API para eliminar
            await deleteAnnoucement(deleteRowData._id);
                
                //console.log(deleteRowData._id);

                dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "success",
                        isText: t("zAdmin.components.form.tableConvocations.delete-success"),
                    })
                );

                // Actualizar la tabla eliminando la fila seleccionada
                const updatedData = data.filter((row) => row._id !== deleteRowData._id);
                setData(updatedData);
            } catch (error) {
                dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "error",
                        isText: t("zAdmin.components.form.tableConvocations.delete-error"),
                    })
                );
            } finally {
                handleCloseDeleteModal();
            }
        }
    };



    const handleDeleteModalOpen = () => {
        setOpenDeleteModal(true);
        setDeleteRowData(rowData); // Guardar la fila seleccionada        
    }


    //Edit nombre handle

    const handleEditConvocations = () => {
        //setOpenCreateConvocations()
        const { name, endDate, limitDate, initialDate } = rowData;

        setValue("name", name);
        setValue("endDate", endDate);
        setValue("initialDate", initialDate);
        setValue("limitDate", limitDate);
        setEditConvocations(true);
        setOpenCreateConvocations(true);
    };

    const handleCreateConvocationsApi = async (
        data: InterfaceModalConvocations
    ) => {
        try {
            if (editConvocations) {
                data._id = rowData._id;
            }

            const responseConvotations = editConvocations
                ? await putAnnouncements(data)
                : await postAnnouncements(data);

            if ("data" in responseConvotations) {

                dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "success",
                        isText: editConvocations
                            ? t("zAdmin.components.form.tableConvocations.update-success")
                            : t("zAdmin.components.form.tableConvocations.conv-success"),
                    })
                );
                setOpenCreateConvocations(false);
            }
        } catch (error) {
            dispatch(
                activeAlertAction({
                    isActive: true,
                    isMode: "error",
                    isText: t("zAdmin.components.form.tableConvocations.state-error"),
                })
            );
            setOpenCreateConvocations(false);
        }
    };

    const handleActiveConvocationsApy = async () => {
        const id = rowData._id;
        try {
            const responseAOD = await putAnnouncementsAOD({ id }).unwrap();

            dispatch(
                activeAlertAction({
                    isActive: true,
                    isMode: "success",
                    isText: t("zAdmin.components.form.tableConvocations.update-state"),
                })
            );




        } catch (error) {
            dispatch(
                activeAlertAction({
                    isActive: true,
                    isMode: "error",
                    isText: t("zAdmin.components.form.tableConvocations.state-error"),
                })
            );
        }

    };

    const actionsMenu = {
        data: rowData,
        active: () => handleActiveConvocationsApy(),
        editConvocations: () => handleEditConvocations(),
        deleteConvocations: () => handleDeleteModalOpen(), // Usa `rowData` del estado
    };

    const transformDate = (date: string) => {
        return date.substr(0, 10)
    }

    return (
        <TableContainer
            component={Paper}
            sx={{
                height: "90%",
                width: "90%",
                margin: "auto",
                marginTop: "2%",
                padding: "2%",
                maxHeight: props.maxHeight
            }}
        >
            {/* Return + Title page */}
            <DoubleContainer
                gridTemplateColumns="auto"
                display="grid"
                width="100%">
                <NavbarLink to="#" onClick={() => window.history.back()}>
                    <ReturnIco />
                    {t("zAdmin.components.form.tableConvocations.back")}
                </NavbarLink>
                <TitleWrapper padding="0"> {title} </TitleWrapper>
            </DoubleContainer>

            {/* Search filter  */}
            <DoubleContainer alingItems="baseline">
                <DoubleContainer>
                    <WrapperInput heigth={"100"} width="100%">
                        <TextField
                            placeholder={title}
                            size={"small"}
                            onChange={(e) => {
                                const value = e.target.value.trim();
                                if (value === "") {
                                    cancelSearch();
                                } else {
                                    requestSearch(value);
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </WrapperInput>
                </DoubleContainer>
            </DoubleContainer>

            {/* Button */}
            <DoubleContainer width="50%" display="flex">
                <ButtonComponent
                    paddingLeft="1%"
                    width="auto"
                    heigth="45px"
                    className="btn-hover"
                    type="button"
                    onClick={handleClickOpenCreateConvocations}
                >
                    {t("zAdmin.components.form.tableConvocations.create-conv")}
                </ButtonComponent>

                {/* Create convocations modal*/}
                <ModalGeneric
                    isOpen={openCreateConvocations}
                    handleClose={handleCloseCreateConvocations}
                >
                    <form
                        noValidate
                        onSubmit={handleSubmit(handleCreateConvocationsApi)}
                    >
                        <DialogContent>
                            <InputComponent
                                {...register("name")}
                                errors={errors.name}
                                label={t("zAdmin.components.form.tableConvocations.conv-name")}
                                type={"text"}
                                placeholder={t("zAdmin.components.form.tableConvocations.into-conv")}
                            />

                            <CalendarComponent<InterfaceModalConvocations>
                                label={t("zAdmin.components.form.tableConvocations.date-start")}
                                subLabel={t("zAdmin.components.form.tableConvocations.info-date")}
                                plataform={props.responsive || "desktop"}
                                formControl={{
                                    control,
                                    name: "initialDate",
                                }}
                            />

                            <CalendarComponent<InterfaceModalConvocations>
                                label={t("zAdmin.components.form.tableConvocations.limit-date")}
                                subLabel={t("zAdmin.components.form.tableConvocations.limit-date-text")}
                                plataform={props.responsive || "desktop"}
                                formControl={{
                                    control,
                                    name: "limitDate",
                                }}
                            />

                            <CalendarComponent<InterfaceModalConvocations>
                                label={t("zAdmin.components.form.tableConvocations.final-date")}
                                subLabel={t("zAdmin.components.form.tableConvocations.final-date-text")}
                                plataform={props.responsive || "desktop"}
                                formControl={{
                                    control,
                                    name: "endDate",
                                }}
                            />
                        </DialogContent>
                        <DialogActions style={{ padding: "2%" }}>
                            <ButtonComponent className="btn-hover">
                                {t("zAdmin.components.form.tableConvocations.create-conv")}
                            </ButtonComponent>
                        </DialogActions>
                    </form>
                    <Loading
                        open={isLAnoun || isPutAnoun}
                        key={"loadingModal"}
                    />
                </ModalGeneric>
            </DoubleContainer>

            {/* Table Code */}
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                <TableHead>
                    <TableRow>
                        {colum.map((items) => (
                            <TableCell
                                key={items.id}
                                align={items.align}
                                style={{ minWidth: items.minWidth, fontWeight: items.fontWeight }}
                            >
                                {items.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {(rowsPerPage > 0
                        ? data.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                        )
                        : data
                    ).map((row, index) => (
                        <TableRow key={row._id}>
                            <TableCell style={{ width: 160 }} align="center">
                                {row.name}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                {transformDate(row.initialDate)}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                {transformDate(row.limitDate)}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                {transformDate(row.endDate)}
                            </TableCell>
                            {/* <TableCell style={{ width: 160 }} align="center">
                                {transformDate(row.createdAt)}
                            </TableCell> */}
                            <TableCell style={{ width: 160 }} align="center">
                                {row.active ? t("zAdmin.components.form.tableConvocations.active") : t("zAdmin.components.form.tableConvocations.inactive")}
                            </TableCell>
                            <TableCell
                                style={{
                                    width: 160,
                                    cursor: "pointer"
                                }}
                                align="center"
                                onClick={(e) => handleClickGetRow(row)}
                            >
                                <MoreComponent
                                    items={dataMenuConvocations(t, actionsMenu)}
                                    props={{}}
                                    key={row._id}
                                    data={row}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>

                <ModalGeneric isOpen={openDeleteModal} handleClose={handleCloseDeleteModal}>
                    <DialogContent>
                        <p>{t("zAdmin.components.form.tableConvocations.confirm-delete")}</p>
                        <p>
                            {t("zAdmin.components.form.tableConvocations.related-projects-warning")}
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <ButtonComponent onClick={handleCloseDeleteModal}>
                            {t("zAdmin.components.form.tableConvocations.cancel")}
                        </ButtonComponent>
                        <ButtonComponent
                            className="btn-hover"
                            onClick={handleConfirmDelete}
                        >
                            {t("zAdmin.components.form.tableConvocations.confirm")}
                        </ButtonComponent>
                    </DialogActions>
                </ModalGeneric>


                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            colSpan={3}
                            count={data ? data.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    "label": "filas por página",
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelRowsPerPage={t("zAdmin.components.paginate.row-per-page")}

                        />
                    </TableRow>
                </TableFooter>
            </Table>

            <Loading open={isLoading || isActiveAnoun} key={"loadingTable"} />

        </TableContainer>
    );
};
